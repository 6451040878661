import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { fetchGet } from '../component/Fetch';
import { Link } from 'react-router-dom';
import { ApiBaseURL } from '../component/Conf';
import { RoleChipStack } from '../component/RoleChip';
import { ScoreChip } from '../component/ScoreChip';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  {
    field: 'psn_id',
    headerName: 'PSN ID',
    width: 200,
  },
  {
    field: 'nickname',
    headerName: '暱稱',
    width: 200,
  },
  {
    field: 'score',
    headerName: '成就積分',
    width: 150,
    renderCell: (params) => {
      return <ScoreChip value={params.row.score} sieze="small" />
    }
  },
  {
    field: 'roles',
    headerName: '擁有角色',
    sortable: false,
    width: 200,
    renderCell: (params) => {
      return <RoleChipStack
        spacing={0.5}
        justifyContent="flex-end"
        roles={{
          hasTank: params.row.has_tank,
          hasHealer: params.row.has_healer,
          hasDD: params.row.has_dd,
          hasPvP: params.row.has_pvp
        }}
      />
    }
  },
];

const GuildMemberView = () => {
  const [members, setMembers] = useState([]);
  const [pageSize, setPageSize] = React.useState(25);

  useEffect(() => {
    fetchGet(ApiBaseURL + '/members')
      .then(
        res => {
          if (!res.ok) throw new Error(res.status);
          return res.json();
        }
      )
      .then(d => {
        setMembers(d.data.members);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            公會成員
          </Typography>
          <Link to={'/main'}>
            <Button>回首頁</Button>
          </Link>
        </Toolbar>
      </AppBar>
      <Grid container justifyContent={'center'} marginTop={5}>
        <Grid item container xs={11}>
          <Box sx={{ height: 600, width: '100%'}}>
            <DataGrid
              rows={members}
              columns={columns}
              getRowId={(row) => row.mid}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[25, 50, 100]}
              pagination
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: 'score', sort: 'desc' }],
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default GuildMemberView